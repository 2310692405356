<template>
  <el-dialog
    :title="$t('role.updateRole')"
    v-model="isShow"
    width="1000px"
    :before-close="handleClose"
  >
    <el-form
      ref="roleForm"
      :inline="true"
      :model="roleInfo"
      size="mini"
      :rules="roleRules"
    >
      <div class="row">
        <el-form-item :label="$t('role.name')" prop="name">
          <el-input
            v-model.trim="roleInfo.name"
            :placeholder="$t('system.pleaseInput') + $t('role.name')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('role.system')" prop="tenantType">
          <el-select
            filterable
            v-model="roleInfo.tenantType"
            :placeholder="$t('system.pleaseSelect') + $t('role.system')"
            @change="resetFields"
          >
            <el-option
              :label="item.name"
              :value="'' + item.id"
              v-for="(item, index) in systemList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>

    <el-container>
      <el-aside width="300px" style="height: 400px; overflow-y: scroll">
        <el-tree
          :props="treeProps"
          @node-click="onNodeClick"
          :data="treeData"
          show-checkbox
          @check-change="onCheckChange"
          node-key="id"
          ref="tree"
          @check="checkDataTree"
        ></el-tree>
      </el-aside>
      <el-main>
        <el-table
          stripe
          ref="multipleTable"
          :data="tableData"
          height="400px"
          @selection-change="handleSelectionChange"
          @select="selectone"
        >
          <el-table-column type="selection" width="70px"></el-table-column>
          <el-table-column
            prop="permission"
            align="center"
            show-overflow-tooltip
            :label="$t('role.sourceCode')"
            min-width="240"
          ></el-table-column>
          <el-table-column
            prop="name"
            align="center"
            show-overflow-tooltip
            :label="$t('role.sourceName')"
            width="180"
          ></el-table-column>
        </el-table>
      </el-main>
    </el-container>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose" size="mini">{{
          $t("system.cancel")
        }}</el-button>
        <el-button type="primary" @click="doUpdateRole" size="mini">{{
          $t("system.confirm")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import accountServer from "../source/account";
export default {
  props: ["isShowDialog", "roleId"],
  emits: ["onDialogClose"],
  data() {
    return {
      roleInfo: {
        id: null,
        name: null,
        tenantType: 1,
      },
      roleRules: {
        name: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("role.name"),
            trigger: "blur",
          },
        ],
        tenantType: [
          {
            required: true,
            message: this.$t("system.pleaseSelect") + this.$t("role.system"),
            trigger: "blur",
          },
        ],
      },
      systemList: [
        { id: 1, name: "道骊Admin" },
        { id: 2, name: "道骊OMS" },
        { id: 3, name: "道骊CBS" },
        { id: 4, name: "道骊BMS" },
        { id: 5, name: "道骊CBS-Client" },
        { id: 8, name: "货站OMS" },
        { id: "c", name: "CBS-T86" },
      ],

      treeProps: {
        label: "label",
        children: "children",
        isLeaf: "leaf",
      },
      treeData: [],
      buttonlist: [],
      tableData: [],

      menuIdList: [],
      resourceIdList: [],
    };
  },
  computed: {
    isShow() {
      return this.isShowDialog;
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.getRoleDetail();
    });
  },
  methods: {
    getRoleDetail() {
      accountServer.roleDetail({ roleId: this.roleId }).then((res) => {
        this.roleInfo = {
          id: res.data.id,
          name: res.data.name,
          tenantType: res.data.tenantType,
        };
        this.resourceIdList = res.data.authority.resourceIdList;
        this.menuIdList = res.data.authority.menuIdList;

        accountServer.queryResources(this.roleInfo).then((res2) => {
          this.buttonlist = res2.data ? res2.data : [];
          this.buttonlist.forEach((item) => {
            this.resourceIdList.some((itemself) => {
              if (itemself == item.id) {
                item.ischecked = true;
                return true;
              }
            });
          });
        });

        accountServer.queryMenuTree(this.roleInfo).then((res3) => {
          this.treeData = res3.data ? res3.data : [];
          this.$nextTick(() => {
            this.menuIdList.forEach((i) => {
              var node = this.$refs.tree.getNode(i);
              if (node && node.isLeaf) {
                this.$refs.tree.setChecked(node, true);
              }
            });
          });
        });
      });
    },

    queryMenuTree() {
      accountServer.queryMenuTree(this.roleInfo).then((res) => {
        this.treeData = res.data ? res.data : [];
      });
    },

    queryResources() {
      accountServer.queryResources(this.roleInfo).then((res) => {
        this.buttonlist = res.data ? res.data : [];
      });
    },

    doUpdateRole() {
      this.$refs["roleForm"].validate((valid) => {
        if (valid) {
          var menuIdList = [];
          var resourceIdList = [];
          menuIdList = this.$refs.tree
            .getCheckedKeys()
            .concat(this.$refs.tree.getHalfCheckedKeys());
          this.buttonlist.forEach((item) => {
            if (item.ischecked) {
              resourceIdList.push(item.id);
            }
          });

          accountServer
            .updateRole({
              menuIdList,
              resourceIdList,
              ...this.roleInfo,
            })
            .then(() => {
              this.$message.success(this.$t("role.updateSuccess"));
              this.handleClose();
            });
        }
      });
    },

    resetFields() {
      this.tableData = [];
      this.queryMenuTree();
      this.queryResources();
    },

    handleClose() {
      this.$emit("onDialogClose");
    },
    onNodeClick(node) {
      var that = this;
      var buttonlist = this.buttonlist;
      var showButtonList = [];
      for (var i in buttonlist) {
        if (node.id == buttonlist[i].menuId) {
          showButtonList.push(buttonlist[i]);
        }
      }
      this.menuId = node.id;
      var keys = this.$refs.tree.getCheckedKeys();
      keys.push(node.id);
      this.tableData = showButtonList;

      setTimeout(() => {
        for (var k in showButtonList) {
          if (showButtonList[k].ischecked) {
            that.$refs.multipleTable.toggleRowSelection(
              showButtonList[k],
              true
            );
          }
        }

        this.buttonlist = buttonlist;
      }, 100);
    },
    onCheckChange(data, checked) {
      if (checked) {
        this.onNodeClick(data);
      }
      // console.log(data, checked, indeterminate);
    },
    checkDataTree(node, checked) {
      var flag = false;
      checked.checkedKeys.forEach((item) => {
        if (item == node.id) {
          flag = true;
        }
      });
      if (flag) {
        this.onNodeClick(node);
        this.selectAllbutton(node, flag);
      } else {
        this.selectAllbutton(node, flag);
      }
    },
    handleSelectionChange(val) {
      var that = this;
      if (this.tableData.length > 0) {
        var menuId = this.tableData[0].menuId;
        if (val.length == 0) {
          for (var i in this.buttonlist) {
            if (menuId == this.buttonlist[i].menuId) {
              this.buttonlist[i].ischecked = false;
            }
          }
        }
        if (val.length > 0) {
          var keys = this.$refs.tree.getCheckedKeys();
          var goflag = true;
          for (var s in keys) {
            if (menuId == keys[s]) {
              goflag = false;
            }
          }
          if (goflag) {
            keys.push(menuId);
            that.$refs.tree.setCheckedKeys(keys);
          }
          setTimeout(() => {
            for (var j in val) {
              that.$refs.multipleTable.toggleRowSelection(val[j], true);
            }
          }, 100);
        }
      }
    },
    selectone(selection, row) {
      var flag = false;
      selection.forEach((item) => {
        if (item.id == row.id) {
          flag = true;
        }
      });
      for (var i in this.buttonlist) {
        if (row.id == this.buttonlist[i].id) {
          this.buttonlist[i].ischecked = flag;
          break;
        }
      }
    },
    getbuttonIds(val, flag) {
      var that = this;
      for (var i in this.buttonlist) {
        if (this.buttonlist[i].menuId == val) {
          this.buttonlist[i].ischecked = flag;
        }
      }
      if (
        that.tableData &&
        that.tableData.length > 0 &&
        that.tableData[0].menuId == val
      ) {
        if (flag) {
          for (var k in that.tableData) {
            setTimeout(() => {
              that.$refs.multipleTable.toggleRowSelection(
                that.tableData[k],
                true
              );
            }, 200);
          }
        } else {
          that.$refs.multipleTable.clearSelection();
        }
      }
      that.$forceUpdate();
    },
    selectAllbutton(data, flag) {
      this.getbuttonIds(data.id, flag);
      if (data.children && data.children.length > 0) {
        for (var i in data.children) {
          this.getbuttonIds(data.children[i].id, flag);
          if (
            data.children[i].children &&
            data.children[i].children.length > 0
          ) {
            for (var k in data.children[i].children) {
              this.getbuttonIds(data.children[i].children[k].id, flag);

              if (
                data.children[i].children[k].children &&
                data[i].children[k].children.length > 0
              ) {
                for (var j in data.children[i].children[k].children) {
                  this.getbuttonIds(
                    data.children[i].children[k].children[j].id,
                    flag
                  );
                }
              }
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.row {
  display: flex;
  align-items: center;

  :deep(.el-form-item__label) {
    font-size: 12px;
  }
  :deep(.el-input--mini) {
    max-width: 180px;
  }
}
</style>
